import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import logo_aer from "../images/aer2.png"
import logo_eset from "../images/eset-300x152.png"
import logo_avg from "../images/avg-300x118.png"
import logo_synology from "../images/synology-300x80.png"

const zm_linia_krotka = <div class="aaa_linia_krotka"> </div>;

const IndexPage = () => (
  <Layout>
    <SEO title="LOST-IT" keywords={[`LOST-IT`, `obsługa informatyczna firm`, `serwis`]} />

    <Link to="/" >
      <span class="aaa_dot"></span><span class="aaa_dot"></span><span class="aaa_dot3"></span>
    </Link>

    <div class="aaa_linia"> </div>
    <div class="aaa_firmy_logo">
    <img src={logo_aer} alt="Logo_AER" class="aaa_firmy_logo"></img>
    <img src={logo_eset} alt="Logo_ESET" class="aaa_firmy_logo"></img>
    <img src={logo_avg} alt="Logo_AVG" class="aaa_firmy_logo"></img>
    <img src={logo_synology} alt="Logo_Synology" class="aaa_firmy_logo"></img>
  
    </div>

    <p class="aaa_informacje">Oferujemy usługi informatyczne o szerokim zakresie działania. Począwszy od jednorazowej pomocy, poprzez wsparcie informatyczne kończąc na specjalistycznych wdrożeniach. Proponujemy Państwu różne warianty umów serwisowych.</p>
{zm_linia_krotka}
    <h3>Strony internetowe</h3>
<p class="aaa_informacje">Strony www i serwisy internetowe to rozwiązania, o których nie mogą zapomnieć współczesne firmy i organizacje. Oferujemy naszym klientom kompleksowe rozwiązania z zakresu projektowania i wykonywania stron internetowych.</p>

{zm_linia_krotka}
    <h3>Bezpieczeństwo</h3>
    <p class="aaa_informacje">Bezpieczeństwo
W dzisiejszych czasach oprogramowanie firewall + program antywirusowy to często nie wystarczające minium zabezpieczenia sieci komputerowych. Pojawia się coraz więcej nowych i bardziej wyszukanych ataków, które łatwo i szybko mogą obejść podstawowe zabezpieczenia. Dziś bezpieczną infrastruktórę IT tworzy kombinacja produktów, które całościowo zabezpieczają przed nowymi zagrożeniami.</p>

{zm_linia_krotka}
  <h3>Archiwizacja</h3>
  <p class="aaa_informacje">Użytkownicy komputerów bardzo często zapominają o tworzeniu kopii zapasowych, bądź wykonanie kopii wymaga dodatkowych urządzeń lub zbyt dużej pracy od użytkownika. Oferujemy proste i przyjazne rozwiązania, które pozwolą kompleksowo zabezpieczyć wszystkie istotne dane w firmach i instytucjach. Przygotowujemy kompletne archiwizacje kluczowych danych poczynając od przygotowania komputerów i automatycznych skryptów backupowych kończąc na wdrożeniu specjalistycznych rozwiązań macierzy dyskowych.</p>

{zm_linia_krotka}
  <h3>Umowy serwisowe w szerokim zakresie</h3>
  <p class="aaa_informacje">
&bull; administrowanie serwerami oraz infrastrukturą sieciową<br/>
&bull; instalacje i przywracanie systemu operacyjnego<br/>
&bull; wsparcie telefoniczne<br/>
&bull; pogwarancyjny serwis sprzętu komputerowego<br/>
&bull; zarządzanie serwisami www<br/>
&bull; doradztwo techniczne i dobór oprogramowania<br/>
&bull; konfiguracje sieci LAN oraz WiFi<br/>
&bull; nadzór nad sprzętem komputerowym<br/>
&bull; tworzenie kopii bezpieczeństwa i archiwizację danych<br/>
&bull; instalacje urządzeń peryferyjnych<br/>
</p>


  </Layout>
)

export default IndexPage
